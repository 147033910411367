var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-3"
  }, [_vm._v(" Search ")]), _c('div', {
    staticClass: "col-sm-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchForm,
      expression: "searchForm"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "type item name..."
    },
    domProps: {
      "value": _vm.searchForm
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.searchForm = $event.target.value;
      }, _vm.debounceSearch]
    }
  }), _vm.typing ? _c('span', [_c('em', [_vm._v(" Mencari .. ")])]) : _vm._e()]), _c('div', {
    staticClass: "col-sm-1"
  }, [_c('router-link', {
    staticClass: "btn btn-block btn-primary",
    attrs: {
      "to": {
        path: 'premium/create'
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  })])], 1), _c('div', {
    staticClass: "col-sm-1"
  }, [_c('router-link', {
    staticClass: "btn btn-block btn-primary",
    attrs: {
      "to": {
        path: 'items/bulk-upload-premium'
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-upload"
  })])], 1)]), _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('label', {
    staticClass: "col-sm-auto",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Count ")]), _c('strong', {
    staticClass: "col-sm-auto"
  }, [_vm._v(_vm._s(_vm.formatNumber(_vm.totalRows)))])]), _c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "isLoading": _vm.isLoading,
      "responsive": "xl",
      "show-empty": "",
      "striped": "",
      "busy": _vm.isLoading,
      "fields": _vm.fields,
      "hover": "",
      "items": _vm.items
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + ". ")];
      }
    }, {
      key: "cell(name)",
      fn: function (row) {
        return [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              path: '/items/detail/' + row.item.item_id
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.name) + " ")])];
      }
    }, {
      key: "cell(vendor)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "150px"
          }
        }, [_vm._v(" " + _vm._s(row.item.vendor ? row.item.vendor : '-') + " ")])];
      }
    }, {
      key: "cell(vendor_id_ax)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "150px"
          }
        }, [_vm._v(" " + _vm._s(row.item.vendor_id_ax ? row.item.vendor_id_ax : '-') + " ")])];
      }
    }, {
      key: "cell(premium_idax)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "150px"
          }
        }, [_vm._v(" " + _vm._s(row.item.premium_idax ? row.item.premium_idax : '-') + " ")])];
      }
    }, {
      key: "cell(created)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "250px"
          }
        }, [_vm._v(" " + _vm._s(row.item.created ? _vm.formatDate(row.item.created) : '-') + " ")])];
      }
    }, {
      key: "cell(modified)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "250px"
          }
        }, [_vm._v(" " + _vm._s(row.item.modified ? _vm.formatDate(row.item.modified) : '-') + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "btn-group",
          attrs: {
            "role": "group",
            "aria-label": "Basic example"
          }
        }, [_c('router-link', {
          staticClass: "link btn btn-primary",
          attrs: {
            "to": {
              path: '/ax/premium-ax/' + row.item.id
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil"
        })])], 1)];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-row', {
    staticClass: "justify-content-start ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }